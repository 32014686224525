.projects-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.projects-logo {
	display: flex;
	position: fixed;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
	background-color: orange;
	padding: 15px;
}

.projects-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 180px;
}

@media (max-width: 600px) {
	.projects-container {
		padding-top: 80px;
	}

	.projects-container .title,
	.projects-container .subtitle {
		padding: 20px;
		box-sizing: border-box;
		margin: 0;
	}

	.projects-project {
		width: calc(100% / 2);
	}

	.projects-subtitle {
		margin-top: 30px;
	}

	.projects-logo {
		height: 20px;
		width: 20px;
		padding: 10px;
	}
	.projects-logo img {
		height: 20px;
		width: 20px;
	}
}
