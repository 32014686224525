@import "../../../data/styles.css";

.animate {
	animation-duration: 0.75s;
	animation-delay: 0.5s;
	animation-name: animate-fade;
	animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
	animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
	animation-name: animate-glow;
	animation-timing-function: ease;
}

@keyframes animate-glow {
	0% {
		opacity: 0;
		filter: brightness(3) saturate(3);
		transform: scale(0.8, 0.8);
	}
	100% {
		opacity: 1;
		filter: brightness(1) saturate(1);
		transform: scale(1, 1);
	}
}

.project {
	mix-blend-mode: normal;
	border-radius: 20px;
	height: 100%;
	background: #4b4b4b;
	overflow-y: hidden;
	visibility: hidden;
}

.visible {
	visibility: visible;
}

.project a {
	text-decoration: none;
}

.project:hover,
.project:focus {
	box-shadow: 3px 3px 7px orange;
	transition: box-shadow 0.5s ease-in-out;
}

.project:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-details {
	padding: 20px 30px;
}

.project-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
	padding: 30px;
	box-sizing: border-box;
	background: white;
	border-radius: 10px 10px 0 0;
}

.project-logo img {
	max-width: 100%;
	max-height: 100%;
}

.project-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.project-description {
	color: var(--secondary-color);
}

.project-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
	margin-bottom: 20px;
}

.project-link-icon {
	padding-left: 5px;
	font-size: 13px;
}

.project-link-text {
	padding-left: 20px;
	font-weight: 700;
}

@media (max-width: 600px) {
	.project-logo {
		height: 120px;
		padding: 20px;
	}

	.project-details {
		padding: 10px 20px;
	}

	.project-description {
		font-size: 12px;
	}
}
