@import "../../../data/styles.css";

.works-body {
	margin-bottom: 10px;
}

.work {
	display: flex;
	margin-bottom: 20px;
	border-radius: 10px;
	min-height: 80px;
	min-width: 300px;
	justify-content: flex-start;
	align-items: center;
}

.work:hover {
	cursor: pointer;
}

.work:hover,
.work.active {
	font-weight: bold;
	background-color: orange;
	transition: background-color 0.3s ease-in-out;
}

.work:hover .work-duration,
.work.active .work-duration {
	color: white;
	font-weight: bold;
}

.works {
	display: flex;
	padding: 0 20px;
}

@media screen and (max-width: 1024px) {
	.works {
		flex-direction: column;
	}
}

.work-image-wrapper {
	height: 60px;
	width: 60px;
	border-radius: 50%;
	background-color: white;
	position: relative;
	margin-left: 10px;
}

.work-image {
	position: absolute;
	max-width: 100%;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 10px;
	box-sizing: border-box;
}

.work-title-wrapper {
	display: flex;
	flex-direction: column;
	padding-left: 20px;
}

.work-title {
	font-size: 16px;
	font-weight: 700;
	color: var(--secondary-color);
}

.work-subtitle {
	font-size: 13px;
	color: var(--secondary-color);
}

.work-duration {
	font-size: 12px;
	color: var(--tertiary-color);
}

.current-description {
	color: white;
	padding: 30px;
	height: 320px;
	overflow-y: scroll;
	margin-left: 30px;
}

@media screen and (max-width: 600px) {
	.current-description {
		margin-left: 0;
		font-size: 14px;
		padding: 10px;
	}

	.current-description ul {
		padding-left: 0;
	}
}

.current-description::-webkit-scrollbar-track {
	background: #505050;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.current-description li {
	margin-bottom: 10px;
}

.shadow {
	position: absolute;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 10px;
	background: red;
  }
  
  .shadow--top {
	top: 0;
	background: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.35), transparent);
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.35), transparent);
	opacity: 0;
  }
  
  .shadow--bottom {
	bottom: 0;
	background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.35), transparent);
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), transparent);
  }