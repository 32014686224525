.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.contact-logo {
	display: flex;
	position: fixed;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	background-color: orange;
	padding: 15px;
}

.contact-link {
	color: orange;
}

.contact-subtitle {
	width: 100% !important;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
}

.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 80px;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

@media (max-width: 600px) {
	.contact-container {
		padding: 0 20px;
	}

	.contact-socials {
		padding-left: 20px;
		padding-bottom: 20px;
	}

	.contact-logo {
		padding: 10px;
	}

	.contact-logo,
	.contact-logo img {
		width: 20px;
		height: 20px;
	}
}
