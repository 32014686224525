@import "../../../data/styles.css";

.card {
	mix-blend-mode: normal;
	border-radius: 20px;
}

.card-container {
	padding: 0 30px 0 0;
}

.card-header {
	display: flex;
	align-items: center;
}

.card-icon {
	font-size: 20px;
	color: var(--tertiary-color);
}

.card-title {
	color: var(--secondary-color);
	font-family: var(--secondary-font);
	font-size: 25px;
	font-weight: 700;
	padding-left: 20px;
}

.card-body {
	padding-top: 20px;
	font-size: 15px;
}

@media (min-width: 600px) {
	.card {
		min-width: 300px;
	}
}

@media screen and (max-width: 600px) {
	.card-container {
		padding: 0;
	}
}
