.all-projects-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	padding-top: 30px;
	padding-bottom: 30px;
	max-width: 100%;
}

.all-projects-project {
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;
}

@media (max-width: 600px) {
	.all-projects-container {
		grid-template-columns: 1fr;
	}
}

@media (min-width: 600px) and (max-width: 1024px) {
	.all-projects-container {
		grid-template-columns: repeat(2, 1fr);
	}
}
