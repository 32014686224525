@import "../../../data/styles.css";

@property --offset {
	syntax: "<length>";
	inherits: false;
	initial-value: 0;
}

.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
}

.nav-background {
	width: 380px;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: #4b4b4b;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
}

.nav-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	align-items: center;
	margin-left: -16px;
	margin-right: 20px;
	margin-top: 11px;
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item.active a:hover {
	text-decoration: none;
}

.nav-item a {
	color: var(--primary-color);
	text-decoration: none;
	text-transform: uppercase;
}

.nav-item a:hover {
	--offset: 0.4em;
	text-underline-offset: var(--offset, 0.2em);
	text-decoration: underline 0.15em;
	transition: --offset 400ms, text-decoration-color 400ms;
	text-decoration-color: orange;
}

@media (max-width: 600px) {
	.navbar {
		margin-left: 25%;
		margin-right: 25%;
		width: 80%;
		font-size: 80%;
	}

	.nav-background {
		width: 80%;
		height: 40px;
	}

	.nav-item {
		font-weight: bold;
	}
}
